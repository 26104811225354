
import { Component, Mixins, Prop } from "vue-property-decorator";
import { Line, mixins } from "vue-chartjs";
import { TimeValuePair } from "@/value-objects/time-value-pair";
import TimeSeriesLineChartData from "@/value-objects/time-series-line-chart-data";
import currency from "@/filters/currency";
import {
    ChartData,
    ChartDataSets,
    ChartOptions,
    ChartTooltipItem,
} from "chart.js";

@Component({
    extends: Line,
    mixins: [mixins.reactiveProp],
})
export default class LineChart extends Mixins(mixins.reactiveProp, Line) {
    @Prop({ required: true, type: Object }) chartData!: TimeSeriesLineChartData;
    @Prop({ required: true, type: String }) datasetLabel!: string;
    @Prop({ required: true, type: Number }) yStepSize!: number;
    @Prop({ required: false, default: true }) showForecast!: boolean;

    get forecastedSeries(): Array<number> {
        const data = this.chartData.series.map((item) => item.value);
        data[data.length - 1] = Math.ceil(
            (this.chartData.series[this.chartData.series.length - 1].value /
                this.getHoursPassedInCurrentMonth()) *
                this.getTotalHoursInCurrentMonth()
        );
        return data;
    }

    getHoursPassedInCurrentMonth(): number {
        const now = new Date();
        return (now.getDate() - 1) * 24 + now.getHours();
    }

    getTotalHoursInCurrentMonth(): number {
        const now = new Date();
        const year = now.getFullYear();
        const month = now.getMonth() + 1;
        const daysInMonth = new Date(year, month, 0).getDate();
        return daysInMonth * 24;
    }

    toFloat(value?: string): string | undefined {
        if (Number.isNaN(value)) {
            return value;
        }
        const input = parseFloat(value as string);
        return Math.floor(input) === input
            ? input.toString()
            : input.toFixed(2);
    }

    mounted() {
        // Overwriting base render method with actual data.
        const datasets: Array<ChartDataSets> = [
            {
                lineTension: 0.2,
                radius: 4,
                fill: false,
                label: this.datasetLabel,
                data: this.chartData.series.map((value: TimeValuePair) => {
                    return value.value;
                }),
                borderColor: "#1565C0",
                pointBackgroundColor: "#1A237E",
                pointBorderColor: "#1A237E",
            },
        ];

        if (this.showForecast) {
            datasets.push({
                lineTension: 0.2,
                radius: 4,
                fill: false,
                label: this.datasetLabel,
                data: this.forecastedSeries,
                borderDash: [4, 6],
                borderColor: "#ff2e7e",
                pointBackgroundColor: "#d8266a",
                pointBorderColor: "#b22058",
            });
        }

        this.renderChart(
            {
                labels: this.chartData.series.map((value: TimeValuePair) => {
                    return value.key;
                }),
                datasets: datasets,
            },
            {
                legend: {
                    display: false,
                },
                scales: {
                    xAxes: [
                        {
                            distribution: "series",
                            gridLines: {
                                display: false,
                            },
                            type: "time",
                            time: {
                                unit: "month",
                            },
                            ticks: {
                                fontFamily: "Roboto, sans-serif",
                            },
                        },
                    ],
                    yAxes: [
                        {
                            gridLines: {
                                display: false,
                            },
                            ticks: {
                                fontFamily: "Roboto, sans-serif",
                                stepSize: this.yStepSize,
                                callback: (value: number) => {
                                    if (!this.chartData.isCurrency) {
                                        return value;
                                    }

                                    if (value >= 1000000) {
                                        return currency(value / 1000000)
                                            .split(" ")
                                            .join("M ");
                                    }
                                    if (value >= 100000) {
                                        return currency(value / 1000)
                                            .split(" ")
                                            .join("k ");
                                    } else {
                                        return currency(value);
                                    }
                                },
                            },
                        },
                    ],
                },
                tooltips: {
                    enabled: true,
                    mode: "x-axis",
                    callbacks: {
                        title: (tooltipItems: Array<ChartTooltipItem>) => {
                            return new Date(
                                tooltipItems[0].xLabel as string
                            ).toLocaleString(undefined, {
                                timeZone: this.chartData.timezone,
                                month: "long",
                                year: "numeric",
                            });
                        },
                        label: (
                            tooltipItem: ChartTooltipItem,
                            data: ChartData
                        ) => {
                            let label = "";
                            if (data.datasets?.length) {
                                label = data.datasets[0].label as string;
                            }

                            if (!this.chartData.isCurrency) {
                                return `${label}: ${this.toFloat(
                                    tooltipItem.value
                                )}`;
                            }

                            let value = tooltipItem.value;
                            if (!Number.isNaN(value)) {
                                const val = parseFloat(value as string);
                                value = currency(val);
                            }

                            return `${label}: ${value}`;
                        },
                    },
                },
            } as ChartOptions
        );
    }
}
