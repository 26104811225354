var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('p',{staticClass:"text-center font-weight-thin text-h3 mb-n2 mt-3"},[_vm._v(" All Customer Reviews ")])]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6","offset-md":"6"}},[_c('v-text-field',{attrs:{"append-icon":_vm.mdiMagnify,"clearable":"","placeholder":"Search"},model:{value:(_vm.query),callback:function ($$v) {_vm.query=$$v},expression:"query"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"search":_vm.query,"loading":_vm.isLoading,"items":_vm.customerReviews,"single-expand":true,"item-key":"id","sort-by":['created_at'],"sort-desc":[true],"custom-filter":_vm.$constants.VUETIFY.CUSTOM_SEARCH,"items-per-page":_vm.footerProps.itemsPerPage,"footer-props":_vm.footerProps},on:{"update:items":function($event){_vm.customerReviews=$event}},scopedSlots:_vm._u([{key:"item.created_at",fn:function(props){return [_vm._v(" "+_vm._s(_vm.daysAgo( new Date(props.item.created_at) ))+" ")]}},{key:"item.order_item_category",fn:function(props){return [_c('div',{staticClass:"d-flex"},_vm._l((_vm.getOrderLogos(
                                                props.item
                                                    .order_item_category
                                            )),function(logo){return _c('v-img',{key:logo,attrs:{"alt":"Category Logo","contain":"","max-height":"20","max-width":_vm.getOrderLogos(
                                                    props.item
                                                        .order_item_category
                                                ).length > 1
                                                    ? 30
                                                    : 100,"src":logo}})}),1)]}},{key:"item.order_id",fn:function(props){return [_c('router-link',{staticClass:"font-weight-bold text-decoration-none",attrs:{"to":'/orders/' + props.item.order_id}},[_vm._v(_vm._s(props.item.order_id))]),(props.item.order_id)?_c('v-btn',{staticClass:"ml-2",attrs:{"icon":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.copy(props.item.order_id)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(_vm.mdiContentCopy))])],1):_vm._e()]}},{key:"item.user_id",fn:function(props){return [_c('router-link',{staticClass:"font-weight-bold text-decoration-none",attrs:{"to":'/admin/orders?itemsPerPage=50&page=1&query=' +
                                            props.item.user_id +
                                            '&sortBy=created_at&sortDesc=true'}},[_vm._v(_vm._s(props.item.user_id))]),(props.item.user_id)?_c('v-btn',{staticClass:"ml-2",attrs:{"small":"","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.copy(props.item.user_id)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(_vm.mdiContentCopy))])],1):_vm._e()]}},{key:"item.rating",fn:function(props){return [_c('v-rating',{attrs:{"readonly":"","large":"","value":props.item.rating,"background-color":"orange lighten-3","color":"orange"}})]}},{key:"item.message",fn:function(props){return [_c('pre',{staticStyle:{"white-space":"pre-wrap","max-width":"300px","word-break":"break-all"}},[_vm._v(_vm._s(props.item.message))])]}}])})],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }