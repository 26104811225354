
import { Component, Vue } from "vue-property-decorator";
import FailedPaymentsChart from "@/components/FailedPaymentsChart.vue";
import TestOrdersChart from "@/components/TestOrdersChart.vue";
import TotalPaymentsChart from "@/components/TotalPaymentsChart.vue";
import AdminDashboardChart from "@/components/AdminDashboardChart.vue";
import CrmColumn from "@/components/CrmColumn.vue";
import { ensureAuthenticated } from "@/plugins/firebase";
import { AxiosError, AxiosResponse } from "axios";
import { captureSentryException } from "@/plugins/sentry";
import { ApiResponse } from "@/services/api/axios";
import { DatatableFooterProps, DefaultFooterProps } from "@/types/veutify";
import { mdiMagnify, mdiContentCopy } from "@mdi/js";
import { differenceInDays, formatDistance } from "date-fns";
import DeleteButton from "@/components/DeleteButton.vue";
import LoadingButton from "@/components/LoadingButton.vue";
import CustomerReview from "@/services/api/customer-review";

@Component({
    components: {
        LoadingButton,
        DeleteButton,
        CrmColumn,
        AdminDashboardChart,
        TotalPaymentsChart,
        TestOrdersChart,
        FailedPaymentsChart,
    },
})
export default class AdminCrmIndex extends Vue {
    isLoading = true;
    customerReviews = [];
    query: string = "";
    mdiMagnify: string = mdiMagnify;
    mdiContentCopy: string = mdiContentCopy;
    footerProps: DatatableFooterProps = DefaultFooterProps;
    headers = [
        {
            text: "Date",
            value: "created_at",
        },
        {
            text: "Order ID",
            value: "order_id",
            sortable: false,
        },
        {
            text: "User ID",
            value: "user_id",
            sortable: true,
        },
        {
            text: "Category",
            value: "order_item_category",
            sortable: true,
        },
        {
            text: "Rating",
            value: "rating",
        },
        {
            text: "Message",
            value: "message",
            sortable: false,
        },
    ];

    loadCustomerReviews() {
        this.isLoading = true;
        CustomerReview.index()
            .then((response: AxiosResponse) => {
                this.customerReviews = response.data.data;
            })
            .catch(this.handleAxiosError)
            .finally(() => {
                this.isLoading = false;
            });
    }

    emitErrorNotification(error: Error) {
        this.$root.$emit(
            this.$constants.NOTIFICATION_EVENTS.ERROR,
            error.message
        );
    }

    handleAxiosError(error: AxiosError<ApiResponse>) {
        captureSentryException(error);
        this.emitErrorNotification(
            new Error(error?.response?.data?.message ?? error.message)
        );
    }

    daysAgo(value: Date): string {
        const diffInDays = differenceInDays(new Date(), value);
        if (diffInDays > 1) {
            return `${diffInDays} days ago`;
        }
        return formatDistance(value, new Date(), { addSuffix: true });
    }

    async copy(value: string) {
        try {
            await navigator.clipboard.writeText(value ?? "");
            this.$root.$emit(
                this.$constants.NOTIFICATION_EVENTS.SUCCESS,
                `"${value}" has been copied to your clipboard`
            );
        } catch (error) {
            captureSentryException(error);
        }
    }

    getOrderLogos(category?: string): string[] {
        if (!category) {
            return [];
        }

        const mapping = new Map<string, string[]>([
            ["dstv", ["/img/dstv.svg"]],
            ["eneo-prepay", ["/img/eneo-crm.png"]],
            ["eneo-postpay", ["/img/eneo-crm.png"]],
            [
                "airtime",
                [
                    "/img/mtn-cameroon.png",
                    "/img/orange-cameroon.png",
                    "/img/camtel-cameroon.png",
                    "/img/yoomee-cameroon.png",
                ],
            ],
            ["exchange", ["/img/mobile-money.png", "/img/orange-money.png"]],
            ["camwater", ["/img/camwater.png"]],
            ["canalplus", ["/img/canalplus.png"]],
        ]);

        return mapping.get(category) ?? [];
    }

    mounted() {
        ensureAuthenticated().finally(() => {
            this.loadCustomerReviews();
        });
    }
}
